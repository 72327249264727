.container {
  @apply flex justify-center list-none p-0 m-0;
}

.dot {
  @apply rounded-full opacity-0;
  animation: loader-animation-1 1.3s infinite ease-in;
  transform: rotate(0.1deg); /* Fixes small rendering bug in FF */
}

.dot:nth-child(2) {
  animation-name: loader-animation-2;
}

.dot:nth-child(3) {
  animation-name: loader-animation-3;
}

.dot--medium {
  @apply w-3 h-3;
}

.dot--medium:nth-child(2) {
  @apply mx-2;
}

.dot--small {
  @apply w-2 h-2;
}

.dot--small:nth-child(2) {
  @apply mx-1;
}

.dot--primary {
  background-color: var(--primary-site-color);
}

.dot--white {
  @apply bg-white;
}

.assistiveText {
  @apply absolute -left-[10000px] top-auto w-px h-px overflow-hidden;
}

@keyframes loader-animation-1 {
  0%, 70%, 100% { opacity: 0.2; }
  30% { opacity: 1; }
}

@keyframes loader-animation-2 {
  0%, 15%, 85%, 100% { opacity: 0.2; }
  45% { opacity: 1; }
}

@keyframes loader-animation-3 {
  0%, 30%, 100% { opacity: 0.2; }
  60% { opacity: 1; }
} 