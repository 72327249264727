.container {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 mb-[150px];
}

.filterSection {
  @apply mb-3 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4;
}

.select {
  @apply w-[280px] h-[40px] border rounded-md px-3 py-2 text-[0.8125rem] appearance-none bg-white cursor-pointer;
}

.searchContainer {
  @apply relative;
}

.searchIcon {
  @apply h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2;
}

.searchInput {
  @apply w-[280px] h-[40px] border rounded-md pl-10 pr-3 py-2 text-[0.8125rem];
}

.tableContainer {
  @apply overflow-x-auto;
  height: 400px;
  min-height: 400px;
  border-bottom: 2px solid #e5e7eb;
}

.table {
  @apply min-w-full table-fixed;
}

.colFundraiser {
  width: 15%;
}

.colGroup {
  width: 35%;
}

.colStartDate {
  width: 15%;
}

.colEndDate {
  width: 15%;
}

.colStatus {
  width: 20%;
}

.tableHeader {
  @apply bg-white border-b-2 border-gray-200;
}

.tableHeaderCell {
  @apply px-6 h-10 text-left text-[0.8125rem] font-medium text-[#7A7A7A] tracking-wider cursor-pointer;
}

.sortIcon {
  @apply h-4 w-4 ml-1 flex-shrink-0;
}

.tableRow {
  @apply h-12 hover:bg-gray-50 transition-colors duration-150 ease-in-out cursor-pointer;
}

.tableCell {
  @apply px-6 whitespace-nowrap text-[0.8125rem] font-medium overflow-hidden text-ellipsis;
}

.summaryText {
  @apply text-[0.8125rem] font-medium text-gray-900 truncate;
}