.sectionHeader {
  @apply relative pb-4 mb-4 w-full;
}

.sectionTitle {
  @apply font-bold text-sm mb-2; /* Changed from text-xs to text-sm */
  font-family: 'Inter', sans-serif;
}

.dividerContainer {
  @apply relative;
}

.dividerLine {
  @apply absolute inset-0 flex items-center;
  border-top: 2px solid #e5e7eb;
}