.banner {
  @apply w-full text-center py-2 text-sm font-medium fixed top-0 z-[9999];
  font-family: 'Inter', sans-serif;
}

.development {
  @apply bg-emerald-400 text-white;
}

.sandbox {
  @apply bg-yellow-300 text-[#5f5f5f];
}

.staging {
  @apply bg-blue-400 text-white;
} 