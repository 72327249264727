.overlay {
  @apply fixed inset-0 z-50 bg-white bg-opacity-90 
    flex items-center justify-center;
  backdrop-filter: blur(4px);
}

.content {
  @apply flex flex-col items-center justify-center 
    p-8 rounded-lg text-center;
}

.icon {
  @apply w-16 h-16 text-black mb-6;
}

.title {
  @apply text-2xl font-semibold text-gray-900 mb-3;
  font-family: 'Inter', sans-serif;
}

.text {
  @apply text-gray-600 text-lg;
  font-family: 'Inter', sans-serif;
} 