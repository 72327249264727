.textFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 500;
}

.textarea {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  resize: vertical;
  box-sizing: border-box;
  overflow-y: hidden;
}

.notResizable {
  resize: none;
}

.textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 1px #3b82f6;
}

.noFocusOutline:focus {
  border-color: #e2e8f0;
  box-shadow: none;
}

.characterCount {
  text-align: right;
  font-size: 0.875rem;
  color: #64748b;
}

.redText {
  color: #ef4444;
}

.orangeText {
  color: #f97316;
} 