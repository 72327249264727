.container {
  @apply w-full flex justify-center bg-white;
}

.nav {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 h-24 flex items-center;
}

.list {
  @apply flex items-center space-x-2 text-sm font-inter text-gray-500 pl-0 select-none;
}

.link {
  @apply text-gray-500 hover:text-primary-site-color transition-colors duration-200;
}

.item {
  @apply flex items-center;
}

.separator {
  @apply mr-2;
}

.currentPage {
  @apply text-gray-500;
}

.hidden {
  @apply invisible;
}