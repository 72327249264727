.button {
  @apply relative inline-flex items-center justify-center rounded-md font-medium transition-colors duration-200;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-site-color;
}

/* Variants */
.button--primary {
  @apply bg-primary-site-color text-white hover:bg-primary-site-color-hover;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.button--secondary {
  @apply bg-gray-100 text-gray-700 hover:bg-gray-200;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.button--outline {
  @apply border border-gray-300 bg-white text-gray-700 hover:bg-gray-50;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.button--submit {
  @apply bg-primary-site-color text-white text-sm font-bold font-inter rounded transition-colors;
  @apply disabled:opacity-50 disabled:cursor-not-allowed;
}

.button--submit:hover {
  background-color: var(--primary-site-color-hover);
}

/* Sizes */
.button--small {
  @apply px-3 py-1.5 text-sm;
  min-height: 32px;
}

.button--medium {
  @apply px-4 py-2 text-sm;
  min-height: 40px;
}

.button--large {
  @apply px-6 py-3 text-base;
  min-height: 48px;
}

.button--submit {
  @apply py-4 px-5;
  min-height: 56px;
}

/* Full width */
.button--full-width {
  @apply w-full;
} 