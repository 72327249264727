.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: auto;
}

.withAnnouncement {
  padding-top: 60px; /* Push the layout down by 60px when there is an announcement */
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 80px); /* Adjust based on header and subheader height */
}