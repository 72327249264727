.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipContent {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  text-align: center;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  width: max-content;
  max-width: 300px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipContent::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.tooltipContainer:hover .tooltipContent {
  visibility: visible;
  opacity: 1;
} 