.container {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 mb-[150px];
}

.mainContent {
  @apply flex flex-col space-y-6;
}

.headerSection {
  @apply pb-4 mb-6;
}

.title {
  @apply text-2xl font-semibold text-gray-900;
  font-family: 'Inter', sans-serif;
}

.subContainer {
  @apply flex flex-col md:flex-row md:space-x-6;
}

.leftContainer {
  @apply w-full md:w-3/4;
  padding-top: 1.5rem;
}

.rightContainer {
  @apply hidden md:block w-1/4 bg-white rounded-lg px-4 pb-4 pl-[42px];
  padding-top: 1.5rem;
}

.section {
  @apply bg-white rounded-lg py-6 mb-6;
}

.sectionTitle {
  @apply font-semibold mb-4;
}

.detailsGrid {
  @apply grid grid-cols-1 gap-4;
}

.detailsGrid p {
  @apply text-sm;
}

.detailsGrid p strong {
  @apply text-black;
}

.detailsContent {
  @apply text-gray-600;
}

.descriptionSection {
  padding-top: 0;
}

.detailsSection {
  padding-top: 0;
}

.detailItem {
  @apply mb-6 last:mb-0;
}

.label {
  @apply text-sm font-medium text-gray-700 mb-1;
}

.value {
  @apply text-sm text-gray-600;
}

.addressBlock {
  @apply text-sm text-gray-600 space-y-0.5;
}

.addressBlock p {
  @apply leading-relaxed;
}

/* Add mobile responsiveness like CaseDetail */
@media (max-width: 768px) {
  .mainContent {
    @apply space-y-4;
  }

  .section {
    @apply mb-4;
  }

  .headerSection {
    @apply pb-2 mb-4;
  }

  .leftContainer {
    @apply w-full;
  }

  .rightContainer {
    @apply hidden;
  }

  .descriptionSection {
    @apply mb-4;
  }
}

@media (min-width: 769px) {
  .leftContainer {
    @apply w-3/4;
  }
} 