.container {
  @apply px-12 mb-[150px];
}

.content {
  @apply max-w-[1200px] mx-auto text-center; /* Add text-center to center-align text */
}

.header {
  @apply text-2xl font-bold mb-8 text-gray-700 font-inter;
}

.helpText {
  @apply text-sm font-medium text-gray-500 mb-6;
}

.helpText:last-of-type {
  @apply mb-10;
}

.form {
  @apply w-full space-y-10 max-w-[700px] mx-auto text-left; /* Add text-left to align form fields to the left */
}

.formGroup {
  @apply flex flex-col w-full;
}

.formLabel {
  @apply mb-2 text-sm font-bold text-gray-600 font-inter;
}

.select,
.input {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded;
}

.textarea {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded;
  resize: vertical;
  min-height: 160px;
  max-height: 600px;  /* Increased from 400px to 600px */
}

.characterCount {
  @apply text-right mt-3 text-sm text-gray-500;
}

.orangeText {
  @apply text-orange-500;
}

.redText {
  @apply text-red-500;
}

.signOutButton {
  @apply mt-10 bg-red-600 text-white font-bold py-3 px-5 rounded hover:bg-red-700 transition-colors;
}

.select {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded appearance-none pr-12;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;
}

.textContainer {
  @apply w-full max-w-[700px] mx-auto text-left mb-10; /* Center the container and left-align the text */
}
