.supportMessageBar {
  @apply bg-[var(--primary-site-color-light)] bg-opacity-20 rounded-md mb-6 flex items-center h-12;
}

.iconContainer {
  @apply bg-primary-site-color rounded-full flex items-center justify-center min-w-[18px] min-h-[18px] w-[18px] h-[18px] ml-4 flex-shrink-0;
}

.iconText {
  @apply text-white text-[14px] font-medium leading-none;
}

.messageText {
  @apply text-black ml-4 text-sm;
}

.link {
  @apply font-bold text-primary-site-color hover:text-primary-site-color-hover;
}
