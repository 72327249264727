.subheader {
  @apply bg-white h-12 flex justify-center items-center border-b border-gray-300 relative;
}

.subheaderNav {
  @apply hidden md:flex items-center h-full;
}

.subheaderLink {
  @apply text-gray-400 no-underline p-0 h-full w-[148px] flex items-center justify-center font-inter text-sm font-medium transition-all duration-200 ease-in-out border-b-2 border-transparent;
}

.subheaderLink:hover {
  @apply text-primary-site-color;
}

.divider {
  @apply w-px h-4 bg-gray-300;
}

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Mobile Menu Styles */
.mobileMenuButton {
  @apply md:hidden absolute left-4 text-gray-500 hover:text-gray-700 transition-colors duration-200;
}

.mobileNav {
  @apply md:hidden fixed top-0 left-0 w-64 h-full bg-white transform -translate-x-full transition-transform duration-300 ease-in-out z-50;
  box-shadow: 4px 0 6px -1px rgba(0, 0, 0, 0.1);
}

.mobileNavOpen {
  @apply translate-x-0;
}

.mobileNavHeader {
  @apply h-12 flex items-center justify-end px-4 border-b border-gray-200;
}

.closeButton {
  @apply text-gray-500 hover:text-gray-700 transition-colors duration-200;
}

.mobileNavLinks {
  @apply flex flex-col py-4;
}

.mobileNavLink {
  @apply px-6 py-3 text-gray-600 hover:bg-gray-50 hover:text-primary-site-color transition-colors duration-200 text-sm font-medium;
}

.overlay {
  @apply md:hidden fixed inset-0 bg-black bg-opacity-50 z-40;
}