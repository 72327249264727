.container {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 mb-[150px];
}

.header {
  @apply mb-4;
}

.dividerContainer {
  @apply mt-2 relative;
}

.dividerLine {
  @apply absolute inset-0 flex items-center;
  border-top: 2px solid #e5e7eb;
}

.dividerHighlight {
  @apply relative flex justify-start bg-primary-site-color;
  height: 0.125rem;
  width: 65px;
}

.mainContainer {
  @apply bg-white rounded-lg p-6;
}

.settingsLayout {
  @apply flex gap-6;
}

.sidebar {
  @apply w-48 flex-shrink-0;
}

.content {
  @apply flex-1;
}

.sectionButton {
  @apply w-full py-3 px-3 text-left text-sm font-medium text-gray-700 rounded-md mb-2 transition-colors duration-150 ease-in-out;
}

.sectionButton:hover {
  @apply text-primary-site-color;
}

.sectionTitle {
  @apply text-lg font-semibold text-gray-900 mb-4;
}

.colorPicker {
  @apply flex flex-wrap gap-3;
}

.colorSquare {
  @apply w-10 h-10 rounded cursor-pointer transition-transform duration-150 ease-in-out;
}

.colorSquare:hover {
  @apply transform scale-110;
}

.passwordReset {
  max-width: 400px;
  margin: 20px 0;
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.button {
  background-color: #4a90e2;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
}