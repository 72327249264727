.container {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 mb-[150px];
}

.mainContent {
  @apply flex flex-col space-y-6;
}

.headerSection {
  @apply pb-4 mb-6;
}

.title {
  @apply text-2xl font-semibold text-gray-900;
  font-family: 'Inter', sans-serif;
}

.headerActions {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.commentField {
  @apply h-10 w-full pl-4 rounded border border-gray-300 text-sm;
}

.subContainer {
  @apply flex flex-col md:flex-row md:space-x-6;
}

.leftContainer {
  @apply w-full md:w-3/4;
  padding-top: 1.5rem;
}

.rightContainer {
  @apply hidden;
}

.section {
  @apply bg-white rounded-lg py-6 mb-6;
}

.sectionTitle {
  @apply font-semibold mb-4;
}

.fileUpload {
  @apply border-dashed border-2 border-gray-300 rounded-lg text-center bg-gray-50;
  width: 100%;
  max-width: 500px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem auto;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.fileUploadContent {
  @apply flex items-center gap-2;
  user-select: none;
}

.fileUploadText {
  @apply text-sm text-gray-600;
}

.paperclipIcon {
  @apply text-gray-400;
  width: 20px;
  height: 20px;
}

.browseLink {
  @apply text-primary-site-color font-medium;
  text-decoration: none;
}

.noAttachment {
  @apply text-gray-500 mt-12 text-center font-medium text-sm; /* Changed mt-4 to mt-12 */
  font-family: 'Inter', sans-serif;
}

.activityList {
  @apply space-y-16; /* Changed from space-y-4 to space-y-16 for 64px top margin */
}

.activityItem {
  @apply flex items-start;
}

.avatar {
  @apply mr-3 bg-gray-200 rounded-full p-2;
}

.avatarIcon {
  @apply h-6 w-6 text-gray-500;
}

.activityContent {
  @apply flex-1 ml-0; /* Changed from ml-1 to ml-0 */
  padding-left: 16px;
}

.activityHeader {
  @apply text-sm text-gray-600;
}

.activityUser {
  @apply text-black font-semibold;
}

.activityDate {
  @apply text-gray-500;
}

.activityMessage {
  @apply mt-2 text-gray-600 text-sm whitespace-pre-line;
}

.detailsGrid {
  @apply grid grid-cols-1 gap-4;
}

.detailsGrid p {
  @apply text-sm; /* Set all text in detailsGrid to 14px (text-sm in Tailwind) */
}

.detailsGrid p strong {
  @apply text-black; /* Keep the labels (strong text) black */
}

.detailsContent {
  @apply text-gray-600; /* Set the content text to light grey, matching the activity message */
}

.actions {
  @apply flex flex-col space-y-4 mt-4;
}

.actionItem {
  @apply text-primary-site-color cursor-pointer font-medium text-sm flex items-center;
  font-family: 'Inter', sans-serif;
}

.actionIcon {
  @apply text-black mr-2;
  width: 16px;
  height: 16px;
  stroke-width: 2;
}

.filePreview {
  @apply relative overflow-hidden;
  width: 100px;
  height: 100px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #d6d6d6;
}

.previewImage {
  @apply w-full h-full object-cover;
  border-radius: 0;
}

.uploadProgressOverlay {
  @apply absolute inset-0 flex flex-col items-center justify-center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.progressCircle {
  width: 40px;
  height: 40px;
  transform: rotate(-90deg);
}

.progressCircle path:first-child {
  stroke-linecap: round;
}

.progressCircle path:last-child {
  stroke-linecap: round;
  transition: stroke-dasharray 0.3s ease;
}

.progressText {
  @apply text-white text-sm font-medium mt-1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.modalOverlay {
  @apply fixed inset-0 bg-white flex flex-col items-center justify-center z-50;
  user-select: none;
}

.modalContent {
  @apply relative flex flex-col items-center justify-center;
  padding: 48px;
  width: calc(100% - 96px);
  height: calc(100% - 188px);
  margin: 0 auto;
  overflow: hidden;
  padding-bottom: 164px;
  user-select: none;
}

.modalImage {
  @apply w-auto absolute;
  height: 600px;
  min-height: 400px;
  max-height: calc(72vh - 144px);
  opacity: 1;
  transform: translateX(0);
  object-fit: contain;
  user-select: none;
  -webkit-user-drag: none;
}

.modalImage.slideOutLeft {
  animation: slideOutLeft 0.3s ease forwards;
}

.modalImage.slideOutRight {
  animation: slideOutRight 0.3s ease forwards;
}

.modalImage.slideInLeft {
  animation: slideInLeft 0.3s ease forwards;
}

.modalImage.slideInRight {
  animation: slideInRight 0.3s ease forwards;
}

.closeButton {
  @apply fixed top-8 right-8 z-10;
}

.closeButtonCircle {
  @apply bg-gray-200 hover:bg-gray-300 rounded-full p-2 text-gray-700;
  transition: all 0.2s ease;
}

.modalNavigation {
  @apply absolute bottom-24 left-1/2 transform -translate-x-1/2 flex items-center justify-between w-full px-6;
}

.navButton {
  @apply bg-gray-200 hover:bg-gray-300 rounded-full p-3 text-gray-700;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  z-index: 60;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

/* Show navigation buttons only on larger screens */
@media (min-width: 768px) {
  .navButton {
    display: flex;
  }
}

.navButtonLeft {
  left: 24px;
}

.navButtonRight {
  right: 24px;
}

.imageCounter {
  @apply bg-gray-900 bg-opacity-75 text-white px-3 py-1 rounded-full text-sm;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.filePreviews {
  @apply mt-20;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.documentIcon {
  @apply w-16 h-16 text-gray-400;
}

.fileInfo {
  @apply flex flex-col;
}

.fileName {
  @apply text-sm font-medium;
}

.fileType {
  @apply text-xs text-gray-500;
}

.dragging {
  @apply border-primary-site-color bg-primary-site-color bg-opacity-5;
  transform: scale(1.02);
}

.descriptionSection {
  padding-top: 0;
}

.detailsSection {
  padding-top: 0;
}

.commentActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  min-height: 32px;
  padding: 12px 0;
  align-items: center;
}

.cancelButton {
  height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  color: #6B7280;
  background: transparent;
  border: 1px solid #E5E7EB;
}

.cancelButton:hover {
  background: #F9FAFB;
}

.addCommentButton {
  height: 32px;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background: var(--primary-site-color);
  border: none;
}

.addCommentButton:hover {
  background: var(--primary-site-color-hover);
}

.addCommentButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.uploadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.errorOverlay {
  @apply absolute inset-0 flex flex-col items-center justify-center;
  background: rgba(239, 68, 68, 0.9);
  backdrop-filter: blur(2px);
}

.errorIcon {
  @apply w-8 h-8 text-white mb-2;
}

.errorText {
  @apply text-white text-xs text-center font-medium px-2;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.existingAttachments {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.attachmentItem {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s;
}

.attachmentItem:hover {
  transform: scale(1.02);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.attachmentPreview {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.documentPreview {
  @apply flex flex-col items-center justify-center w-full h-full bg-gray-50;
  padding: 8px;
}

.documentIcon {
  @apply w-8 h-8 text-gray-400 mb-2;
}

.documentTitle {
  @apply text-xs text-gray-600 text-center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.uploadCounter {
  @apply text-gray-500 text-center font-medium text-sm;
  font-family: 'Inter', sans-serif;
  margin-top: 16px;
}

.desktopRightContainer {
  @apply hidden md:block w-1/4 bg-white rounded-lg px-4 pb-4 pl-[42px];
  padding-top: 1.5rem;
}

.mobileDetailsSection {
  @apply hidden bg-white rounded-lg mb-6;
  padding: 0;
}

.mobileCloseButtonContainer {
  @apply block w-full p-4 bg-white md:hidden;
  z-index: 40;
}

.mobileCloseButton {
  @apply w-full py-2 px-4 bg-primary-site-color hover:bg-primary-site-color-hover text-white text-sm font-medium rounded-md transition-colors duration-200;
  height: 40px;
}

@media (max-width: 768px) {
  .mainContent {
    @apply space-y-4;
  }

  .section {
    @apply mb-4;
  }

  .headerSection {
    @apply pb-2 mb-4;
  }

  .attachmentSection {
    @apply mb-4;
  }

  .mobileDetailsSection {
    @apply block;
  }

  .desktopRightContainer {
    @apply hidden;
  }

  .leftContainer {
    @apply w-full;
  }

  .descriptionSection,
  .mobileDetailsSection,
  .attachmentSection {
    @apply mb-4;
  }
}

@media (min-width: 769px) {
  .mobileDetailsSection {
    @apply hidden;
  }

  .leftContainer {
    @apply w-3/4;
  }
}

.damageNoteMessage {
  @apply text-gray-500 text-sm text-center mt-4 p-4 bg-gray-50 rounded-md;
}

/* Add a new class for damage case activity section */
.damageActivitySection {
  padding-top: 0;
}

.rmaContainer {
  @apply relative;
}

.rmaContainer:hover {
  cursor: help;
}

/* Add new class for attachment section */
.attachmentSection {
  @apply bg-white rounded-lg py-6 mb-6;
  padding-top: 0;
}

/* Add animation for progress circle */
@keyframes progress-pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.progressCircle path:last-child {
  animation: progress-pulse 2s ease infinite;
}

/* Add animation for error state */
@keyframes error-shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
}

.errorOverlay {
  animation: error-shake 0.4s ease;
}

.fullScreenOverlay {
  @apply fixed inset-0 z-50 bg-white bg-opacity-90 
    flex items-center justify-center;
  backdrop-filter: blur(4px);
}

.overlayContent {
  @apply flex flex-col items-center justify-center 
    p-8 rounded-lg text-center;
}

.overlayIcon {
  @apply w-16 h-16 text-primary-site-color mb-6;
}

.overlayTitle {
  @apply text-2xl font-semibold text-gray-900 mb-3;
  font-family: 'Inter', sans-serif;
}

.overlayText {
  @apply text-gray-600 text-lg;
  font-family: 'Inter', sans-serif;
}

.modalThumbnails {
  @apply fixed bottom-0 left-0 right-0 flex justify-center gap-4;
  height: 140px;
  background-color: white;
  z-index: 70;
  padding: 24px 24px 32px 24px;
  user-select: none;
}

.modalThumbnail {
  @apply w-20 h-20 cursor-pointer overflow-hidden relative;
  border: 1px solid #d6d6d6;
  transition: all 0.2s ease;
}

.modalThumbnail img {
  @apply w-full h-full object-cover;
  border-radius: 0;
  position: relative;
  user-select: none;
  -webkit-user-drag: none;
}

.modalThumbnailActive {
  border: 1px solid black;
}

.thumbnailOverlay {
  position: absolute;
  inset: 0;
  background-color: hwb(0deg 0% 100% / 7%);
  pointer-events: none;
  z-index: 1;
}

/* Add these new animation keyframes */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes slideOutLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.fileExtensionPreview {
  @apply w-full h-full flex items-center justify-center bg-gray-100 text-gray-600 font-medium;
  font-size: 14px;
  letter-spacing: 0.5px;
}