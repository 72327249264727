.dropdown {
  @apply fixed mt-2 w-[250px] bg-white rounded-md shadow-lg pt-1 ring-1 ring-black ring-opacity-5 focus:outline-none;

  /* Position dropdown on all screens below desktop size (1024px) and iPad Pro */
  @media (max-width: 1024px), (width: 1366px) {
    position: fixed;
    right: 1rem;
    top: 4rem;
  }
}

.userInfo {
  @apply px-4 py-4 flex items-center;
}

.avatar {
  @apply w-10 h-10 rounded-full bg-gray-100 flex items-center justify-center mr-2;
}

.initials {
  @apply text-gray-600 font-medium text-sm;
}

.nameEmail {
  @apply text-sm leading-5 font-medium text-gray-900 truncate;
}

.nameEmailContainer {
  @apply ml-3;
}

.email {
  @apply text-xs leading-4 text-gray-500 truncate;
}

.link {
  @apply block px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100;
}

.divider {
  @apply border-t border-gray-100;
}

.supportSection {
  @apply pt-3;
}

.supportLabel {
  @apply text-[10px] leading-5 font-bold text-gray-500 uppercase tracking-wider mb-1 px-4;
}

.logoutButton {
  @apply block w-full text-left px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-b-md;
}
