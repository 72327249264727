.header {
  @apply bg-primary-site-color w-full flex justify-center items-center sticky top-0 z-50;
  height: 80px;
}

.container {
  @apply w-full mx-auto;
  max-width: 1440px;
  @apply px-4 sm:px-6 md:px-8 lg:px-[7.7rem];
}

.content {
  @apply flex justify-between items-center w-full;
}

.logoLink {
  @apply flex items-center flex-shrink-0;
}

.logo {
  @apply h-10 mr-4 flex-shrink-0 pt-0.5 select-none;
}

.supportText {
  @apply text-white font-normal font-inter text-xl leading-none select-none;
  @apply hidden sm:block;
}

.rightSide {
  @apply flex items-center;
}

.profileContainer {
  @apply relative flex-shrink-0;
}

.dropdownWrapper {
  @apply absolute mt-[0.4rem] w-max;
  /* Position dropdown on right side for desktop */
  @apply right-0;
  
  /* Adjust position for mobile */
  @media (max-width: 768px) {
    position: fixed;
    top: 84px; /* header height (80px) + small gap */
    right: 0;
    margin-right: 16px; /* Match container's padding: px-4 */
  }

  /* Adjust margin for different screen sizes to match container padding */
  @media (min-width: 640px) {
    margin-right: 24px; /* sm:px-6 */
  }
  
  @media (min-width: 768px) {
    position: absolute;
    margin-right: 0;
    right: 0;
  }
}

.userIcon {
  @apply w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center cursor-pointer;
}

.userInitials {
  @apply text-gray-600 font-medium text-sm select-none;
}