.container {
  @apply w-full max-w-7xl mx-auto px-4 sm:px-6 md:px-8 lg:px-12 mb-[150px];
}

.filterSection {
  @apply mb-3 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4;
}

.select {
  @apply w-[280px] h-[40px] border rounded-md px-3 py-2 text-[0.8125rem] appearance-none bg-white cursor-pointer;
}

.searchContainer {
  @apply relative;
}

.searchIcon {
  @apply h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2;
}

.searchInput {
  @apply w-[280px] h-[40px] border rounded-md pl-10 pr-3 py-2 text-[0.8125rem];
}

.tableContainer {
  @apply overflow-x-auto h-[400px] min-h-[400px];
}

.table {
  @apply min-w-full table-fixed border-b-2 border-gray-200;
}

.colCase {
  width: 15%;
}

.colSummary {
  width: 35%;
}

.colType {
  width: 15%;
}

.colStatus {
  width: 15%;
}

.colRequester {
  width: 20%;
}

.tableHeader {
  @apply bg-white border-b-2 border-gray-200;
}

.tableHeaderCell {
  @apply px-6 h-10 text-left text-[0.8125rem] font-medium text-[#7A7A7A] tracking-wider cursor-pointer;
}

.sortIcon {
  @apply h-4 w-4 ml-1 flex-shrink-0;
}

.tableRow {
  @apply h-12 hover:bg-gray-50 transition-colors duration-150 ease-in-out cursor-pointer;
}

.tableCell {
  @apply px-6 whitespace-nowrap text-[0.8125rem] font-medium overflow-hidden text-ellipsis;
}

.caseReference {
  @apply text-primary-site-color font-medium;
}

.summaryText {
  @apply text-[0.8125rem] font-medium text-gray-900 truncate;
}

.requestTypeText {
  @apply text-[0.8125rem] font-medium text-gray-900;
}

.statusBadge {
  @apply px-2 py-1 inline-flex items-center justify-center w-24 text-[0.625rem] font-bold leading-3 rounded-[4px] uppercase;
}

.statusBadge.closed {
  @apply bg-green-100 text-green-800;
}

.statusBadge.in_progress {
  @apply bg-yellow-100 text-yellow-800;
}

.statusBadge.blocked {
  @apply bg-red-100 text-red-800;
}

.statusBadge.open {
  @apply bg-blue-100 text-blue-800;
}

.paginationButton {
  @apply relative inline-flex items-center justify-center w-10 h-10 text-sm font-medium border border-gray-300;
  margin-left: -1px; /* Overlap borders to hide double borders */
}

.disabledButton {
  @apply text-gray-300 cursor-not-allowed;
}

.activeButton {
  @apply text-gray-500 hover:bg-gray-50;
}

.currentPage {
  @apply z-10 bg-primary-site-color text-white border-t border-b border-primary-site-color;
  border-left: none; /* Remove left border */
  border-right: none; /* Remove right border */
  position: relative; /* Ensure z-index works */
  margin-left: -1px; /* Overlap borders to hide double borders */
}

.ellipsis {
  @apply relative inline-flex items-center justify-center w-10 h-10 text-sm font-medium text-gray-700 border-t border-b border-r border-gray-300;
  margin-left: -1px; /* Overlap borders to hide double borders */
}

/* Add rounded corners only to the first and last pagination buttons */
.paginationButton:first-child {
  border-radius: 0.375rem 0 0 0.375rem;
  margin-left: 0; /* Reset margin for the first button */
}

.paginationButton:last-child {
  border-radius: 0 0.375rem 0.375rem 0;
}