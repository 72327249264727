.smallHeader {
  @apply mb-4;
}

.smallTitle {
  @apply text-sm font-semibold text-primary-site-color text-left;
  font-family: 'Inter', sans-serif;
}

.dividerContainer {
  @apply mt-2 relative;
}

.dividerLine {
  @apply absolute inset-0 flex items-center;
  border-top: 2px solid #e5e7eb;
}

.dividerHighlight {
  @apply relative flex justify-start bg-primary-site-color;
  height: 0.125rem;
  width: 104px;
}

/* Large Header Styles */
.largeHeader {
  @apply mb-8;
}

.largeTitle {
  @apply text-2xl font-bold text-gray-700 text-left;
  font-family: 'Inter', sans-serif;
}