.damageForm {
  @apply space-y-6;
}

.formFields {
  @apply space-y-4 bg-white p-0 rounded-lg;
}

.formGroup {
  @apply flex flex-col space-y-2;
}

.formLabel {
  @apply mb-2 text-sm font-bold text-gray-600 font-inter;
}

.select,
.input,
.textarea {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded appearance-none;
}

.select {
  @apply pr-12;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px 16px;
}

.textarea {
  @apply min-h-[160px] resize-y max-h-[600px];
}

.addButton {
  @apply w-full bg-primary-site-color text-white text-sm font-bold font-inter py-4 px-5 rounded transition-colors mt-10 hover:bg-opacity-90 disabled:opacity-50;
}

.itemsTable {
  @apply mt-6 overflow-x-auto;
}

.itemsTable table {
  @apply w-full border-collapse;
}

.itemsTable th {
  @apply bg-gray-50 text-left p-3 text-sm font-bold text-gray-600;
}

.itemsTable td {
  @apply border-t border-gray-200 p-3 text-sm;
}

.removeButton {
  @apply text-red-600 hover:text-red-700 text-sm;
}

.terms {
  @apply space-y-4 mt-6;
}

.checkbox {
  @apply flex items-start space-x-2;
}

.checkbox input {
  @apply mt-1;
}

.checkbox span {
  @apply text-sm text-gray-600;
}

.error {
  @apply text-red-600 text-sm mt-2;
}

.warning {
  @apply text-amber-600 text-sm mt-2;
}

.select:disabled,
.textarea:disabled {
  @apply opacity-50 cursor-not-allowed bg-gray-50;
}

.checkboxWarning {
  @apply text-red-600 text-sm mt-2 font-medium;
} 