.container {
  @apply px-12 mb-[150px];
}

.content {
  @apply max-w-[1200px] mx-auto;
}

.header {
  @apply text-2xl font-bold mb-2 text-gray-700 font-inter;
}

.helpText {
  @apply text-sm font-medium text-gray-500 mb-6;
}

.form {
  @apply w-full max-w-[700px] mx-auto space-y-6;
}

.section {
  @apply bg-white rounded-lg py-6;
}

.sectionContent {
  @apply space-y-6;
}

.sectionTitle {
  @apply text-lg font-semibold text-gray-900 mb-6 font-inter;
}

.formGroup {
  @apply flex flex-col w-full;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
}

.formGroup:first-child {
  @apply mt-0;
}

.dateGroup {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6;
}

.dateGroup > .formGroup {
  @apply mt-0;
}

.formLabel {
  @apply mb-2 text-sm font-bold text-gray-600 font-inter;
}

.helpText {
  @apply text-xs text-gray-500 mt-1;
}

.input {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded;
}

.select {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded appearance-none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236b7280'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px 16px;
  padding-right: 40px;
}

.textarea {
  @apply w-full bg-white text-black border border-gray-300 py-3 px-3 rounded;
  resize: vertical;
  min-height: 160px;
  max-height: 600px;
}

.buttonGroup {
  @apply flex justify-end space-x-4 mt-8;
}

.cancelButton {
  @apply px-4 py-2 border border-gray-300 text-gray-700 rounded font-medium transition-colors duration-150;
  background: white;
}

.cancelButton:hover {
  @apply bg-gray-50;
}

.submitButton {
  @apply px-4 py-2 text-white rounded font-medium disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-150;
  background-color: var(--primary-site-color);
}

.submitButton:hover:not(:disabled) {
  background-color: var(--primary-site-color-hover);
}

.textContainer {
  @apply w-full max-w-[700px] mx-auto mb-8;
}

/* Address Form Styles */
.addressForm {
  @apply mt-6;
}

.addressFormGroup {
  @apply space-y-6;
}

.addressFormFullWidth {
  @apply w-full;
}

.addressCheckbox {
  @apply mb-6;
}

.checkboxLabel {
  @apply inline-flex items-center cursor-pointer select-none;
}

.checkbox {
  @apply h-4 w-4 text-primary-site-color border-gray-300 rounded focus:ring-primary-site-color;
}

.checkboxText {
  @apply ml-2 text-sm text-gray-600 font-medium;
}

.statusBadge {
  @apply px-2 py-1 text-xs font-semibold rounded-full;
}

.pending {
  @apply bg-yellow-100 text-yellow-800;
}

.approved {
  @apply bg-green-100 text-green-800;
}

.rejected {
  @apply bg-red-100 text-red-800;
}

.completed {
  @apply bg-blue-100 text-blue-800;
}

/* Update the address fields grid layout */
.addressFields {
  @apply grid grid-cols-2 gap-4;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
}

.cityField {
  @apply col-span-1;
}

.stateField {
  @apply col-span-1;
}

.zipField {
  @apply col-span-1;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
}

.countryField {
  @apply col-span-1;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
} 